import React from 'react'
import { theme } from 'theme'

import { ISvgProps } from '@/types/main'

export const FiltersIcon: React.FC<ISvgProps> = ({ className }) => (
  <svg
    width="60"
    height="52"
    viewBox="0 0 60 52"
    fill={theme.color.black}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M11.5946 6.31242C11.3747 6.22808 11.1847 6.08049 11.0485 5.88829C10.9124 5.69608 10.8362 5.46787 10.8296 5.23242V2.41992C10.8296 1.82318 10.5925 1.25089 10.1706 0.828932C9.74862 0.406975 9.17632 0.169922 8.57959 0.169922C7.98285 0.169922 7.41055 0.406975 6.9886 0.828932C6.56664 1.25089 6.32959 1.82318 6.32959 2.41992V5.23242C6.32299 5.46787 6.24681 5.69608 6.11066 5.88829C5.97452 6.08049 5.7845 6.22808 5.56459 6.31242C4.14428 6.90277 2.93068 7.90061 2.07694 9.18002C1.2232 10.4594 0.767578 11.9631 0.767578 13.5012C0.767578 15.0393 1.2232 16.5429 2.07694 17.8223C2.93068 19.1017 4.14428 20.0996 5.56459 20.6899C5.78285 20.7767 5.97127 20.9249 6.10704 21.1166C6.24282 21.3083 6.32012 21.5352 6.32959 21.7699V49.5799C6.32959 50.1767 6.56664 50.749 6.9886 51.1709C7.41055 51.5929 7.98285 51.8299 8.57959 51.8299C9.17632 51.8299 9.74862 51.5929 10.1706 51.1709C10.5925 50.749 10.8296 50.1767 10.8296 49.5799V21.7699C10.8391 21.5352 10.9164 21.3083 11.0521 21.1166C11.1879 20.9249 11.3763 20.7767 11.5946 20.6899C13.0149 20.0996 14.2285 19.1017 15.0822 17.8223C15.936 16.5429 16.3916 15.0393 16.3916 13.5012C16.3916 11.9631 15.936 10.4594 15.0822 9.18002C14.2285 7.90061 13.0149 6.90277 11.5946 6.31242ZM8.57959 16.8424C7.91861 16.8291 7.27632 16.6206 6.7335 16.2433C6.19067 15.8659 5.77154 15.3364 5.5288 14.7215C5.28607 14.1066 5.23057 13.4336 5.36928 12.7872C5.50799 12.1408 5.83473 11.5498 6.3084 11.0886C6.78207 10.6274 7.38152 10.3166 8.03139 10.1951C8.68125 10.0737 9.35252 10.1471 9.96077 10.4062C10.569 10.6652 11.0871 11.0983 11.4499 11.651C11.8126 12.2037 12.0039 12.8513 11.9996 13.5124C11.9967 13.9565 11.9056 14.3956 11.7316 14.8042C11.5576 15.2128 11.3041 15.5828 10.9859 15.8926C10.6677 16.2024 10.2912 16.4459 9.87806 16.609C9.46497 16.772 9.0236 16.8514 8.57959 16.8424Z" />
    <path d="M33.0148 31.3099C32.7965 31.2231 32.6081 31.0749 32.4723 30.8832C32.3366 30.6915 32.2593 30.4646 32.2498 30.2299V2.41992C32.2498 1.82318 32.0128 1.25089 31.5908 0.828932C31.1688 0.406975 30.5965 0.169922 29.9998 0.169922C29.4031 0.169922 28.8308 0.406975 28.4088 0.828932C27.9869 1.25089 27.7498 1.82318 27.7498 2.41992V30.2299C27.7403 30.4646 27.663 30.6915 27.5273 30.8832C27.3915 31.0749 27.2031 31.2231 26.9848 31.3099C25.5645 31.9003 24.3509 32.8981 23.4972 34.1775C22.6434 35.4569 22.1878 36.9606 22.1878 38.4987C22.1878 40.0368 22.6434 41.5404 23.4972 42.8198C24.3509 44.0992 25.5645 45.0971 26.9848 45.6874C27.2047 45.7718 27.3947 45.9193 27.5309 46.1116C27.667 46.3038 27.7432 46.532 27.7498 46.7674V49.5799C27.7498 50.1767 27.9869 50.749 28.4088 51.1709C28.8308 51.5929 29.4031 51.8299 29.9998 51.8299C30.5965 51.8299 31.1688 51.5929 31.5908 51.1709C32.0128 50.749 32.2498 50.1767 32.2498 49.5799V46.7674C32.2564 46.532 32.3326 46.3038 32.4687 46.1116C32.6049 45.9193 32.7949 45.7718 33.0148 45.6874C34.4351 45.0971 35.6487 44.0992 36.5024 42.8198C37.3562 41.5404 37.8118 40.0368 37.8118 38.4987C37.8118 36.9606 37.3562 35.4569 36.5024 34.1775C35.6487 32.8981 34.4351 31.9003 33.0148 31.3099ZM29.9998 41.7499C29.3358 41.7544 28.6854 41.5616 28.1312 41.1959C27.5769 40.8302 27.1437 40.3082 26.8865 39.696C26.6293 39.0839 26.5597 38.4091 26.6865 37.7573C26.8133 37.1055 27.1308 36.506 27.5988 36.0349C28.0667 35.5638 28.664 35.2423 29.3149 35.1111C29.9659 34.9799 30.6411 35.045 31.255 35.298C31.8689 35.5511 32.3938 35.9808 32.7632 36.5326C33.1326 37.0843 33.3298 37.7334 33.3298 38.3974C33.3298 39.2827 32.9797 40.132 32.3558 40.7601C31.732 41.3882 30.885 41.744 29.9998 41.7499Z" />
    <path d="M59.2499 25.9999C59.2418 24.4599 58.781 22.9562 57.9249 21.676C57.0688 20.3958 55.8551 19.3956 54.4349 18.7999C54.2133 18.7182 54.0216 18.5713 53.885 18.3785C53.7485 18.1857 53.6735 17.9561 53.6699 17.7199V2.41992C53.6699 1.82318 53.4328 1.25089 53.0109 0.828932C52.5889 0.406975 52.0166 0.169922 51.4199 0.169922C50.8232 0.169922 50.2509 0.406975 49.8289 0.828932C49.4069 1.25089 49.1699 1.82318 49.1699 2.41992V17.7199C49.1663 17.9561 49.0913 18.1857 48.9548 18.3785C48.8182 18.5713 48.6265 18.7182 48.4049 18.7999C46.9777 19.3868 45.7572 20.3846 44.8982 21.6665C44.0393 22.9485 43.5807 24.4568 43.5807 25.9999C43.5807 27.543 44.0393 29.0514 44.8982 30.3333C45.7572 31.6153 46.9777 32.613 48.4049 33.1999C48.6265 33.2817 48.8182 33.4286 48.9548 33.6214C49.0913 33.8141 49.1663 34.0437 49.1699 34.2799V49.5799C49.1699 50.1767 49.4069 50.749 49.8289 51.1709C50.2509 51.5929 50.8232 51.8299 51.4199 51.8299C52.0166 51.8299 52.5889 51.5929 53.0109 51.1709C53.4328 50.749 53.6699 50.1767 53.6699 49.5799V34.2799C53.6735 34.0437 53.7485 33.8141 53.885 33.6214C54.0216 33.4286 54.2133 33.2817 54.4349 33.1999C55.8551 32.6042 57.0688 31.604 57.9249 30.3238C58.781 29.0437 59.2418 27.54 59.2499 25.9999ZM51.4199 29.3299C50.7613 29.3299 50.1175 29.1346 49.5698 28.7687C49.0222 28.4028 48.5954 27.8827 48.3434 27.2743C48.0913 26.6658 48.0254 25.9962 48.1539 25.3503C48.2824 24.7043 48.5995 24.111 49.0652 23.6453C49.5309 23.1795 50.1243 22.8624 50.7702 22.7339C51.4162 22.6054 52.0857 22.6714 52.6942 22.9234C53.3027 23.1754 53.8228 23.6023 54.1887 24.1499C54.5546 24.6975 54.7499 25.3413 54.7499 25.9999C54.7499 26.8831 54.3991 27.7301 53.7746 28.3546C53.1501 28.9791 52.3031 29.3299 51.4199 29.3299Z" />
  </svg>
)
