import React from 'react'
import { theme } from 'theme'

import { ISvgProps } from '@/types/main'

export const RedoIcon: React.FC<ISvgProps> = ({ className }) => (
  <svg
    width="56"
    height="63"
    viewBox="0 0 56 63"
    fill={theme.color.black}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M53.1854 31.7889H53.1324C52.5555 31.8035 52.0079 32.0464 51.6098 32.4643C51.2117 32.8822 50.9957 33.441 51.0092 34.018C51.1226 38.5491 49.9131 43.0151 47.5287 46.8698C45.1444 50.7245 41.6884 53.8009 37.5834 55.7227C33.4785 57.6446 28.9025 58.3286 24.415 57.6912C19.9275 57.0538 15.7229 55.1225 12.3155 52.1336C8.90813 49.1447 6.44554 45.2276 5.22888 40.8614C4.01223 36.4952 4.09424 31.8691 5.46489 27.5487C6.83555 23.2284 9.43542 19.401 12.9466 16.5348C16.4578 13.6685 20.7282 11.8875 25.2355 11.4095C26.0714 11.4095 26.35 12.3485 25.8735 12.9114L24.3717 14.6695C23.9963 15.1081 23.8105 15.6779 23.8552 16.2535C23.8998 16.829 24.1713 17.3633 24.6099 17.7387C25.0485 18.1141 25.6183 18.2999 26.1938 18.2552C26.7694 18.2106 27.3037 17.9391 27.6791 17.5005L34.0877 10.0191C34.4618 9.58066 34.6467 9.01169 34.6017 8.43707C34.5568 7.86246 34.2858 7.32914 33.8481 6.95414L26.3667 0.545525C26.1503 0.354302 25.8981 0.208056 25.6246 0.115307C25.3511 0.0225584 25.062 -0.0148392 24.7739 0.0052976C24.4858 0.0254344 24.2047 0.102702 23.9468 0.232596C23.6889 0.36249 23.4594 0.542414 23.2717 0.761881C23.0841 0.981349 22.942 1.23597 22.8537 1.51091C22.7654 1.78585 22.7327 2.07561 22.7576 2.3633C22.7824 2.651 22.8642 2.93087 22.9983 3.18662C23.1324 3.44236 23.3161 3.66886 23.5386 3.85292L25.5531 5.58046C26.3138 6.22968 26.0658 6.86497 25.4556 7.00708L25.1017 7.03494C18.0152 7.69651 11.4559 11.063 6.78689 16.4349C2.11786 21.8068 -0.301971 28.771 0.0301777 35.8806C0.362326 42.9903 3.42092 49.6986 8.57045 54.6118C13.72 59.5249 20.5645 62.2652 27.6819 62.2632C27.9131 62.2632 28.15 62.2632 28.384 62.2632C35.721 62.0807 42.6849 58.9913 47.7441 53.6745C52.8033 48.3577 55.5433 41.249 55.3615 33.9121C55.3477 33.3442 55.1123 32.8042 54.7057 32.4074C54.2991 32.0107 53.7535 31.7887 53.1854 31.7889Z" />
  </svg>
)
