import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { RightChevronIcon } from '@/components/_svg/icons/ChevronIcon'
import { FiltersIcon } from '@/components/_svg/icons/FiltersIcon'
import { IVideoFilterKeys } from '@/reducers/videoFilters'
import { DEVICE_WIDTH } from '@/utils/constants'
import { showNbItems } from '@/utils/style'

interface IProps {
  toggleFiltersSelector: () => void
  removableFilterList: JSX.Element[] | [IVideoFilterKeys, unknown][]
  className?: string
}

export const FiltersMobileButton: React.FC<IProps> = ({
  toggleFiltersSelector,
  removableFilterList,
  className,
}) => (
  <FiltersButton onClick={toggleFiltersSelector} className={className}>
    <LeftWrapper>
      <StyledFiltersIcon />
      <FilterTitle $nbSelectedValues={removableFilterList.length}>
        <FormattedMessage
          defaultMessage="Filtrer"
          description="FiltersMobileButton: title filter"
        />
      </FilterTitle>
    </LeftWrapper>
    <StyledRightChevronicon />
  </FiltersButton>
)

const FiltersButton = styled.button`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.greyLight};
  border-radius: 62px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.s};
  width: 100%;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    display: none;
  }
`
const LeftWrapper = styled.div`
  align-items: center;
  display: flex;
`
const FilterTitle = styled.p<{ $nbSelectedValues: number }>`
  margin-left: ${({ theme }) => theme.spacing.xs};
  text-align: left;
  ${({ $nbSelectedValues }) => showNbItems($nbSelectedValues)};
`
const StyledRightChevronicon = styled(RightChevronIcon)`
  fill: ${({ theme }) => theme.color.terracota};
  height: 12px;
  width: 12px;
`
const StyledFiltersIcon = styled(FiltersIcon)`
  fill: ${({ theme }) => theme.color.terracota};
  height: ${({ theme }) => theme.spacing.s};
  width: ${({ theme }) => theme.spacing.s};
`
