import React from 'react'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components'

const FILTER_WIDTH = 150
const FILTER_HEIGHT = 48

export const LessonListFilterSkeleton: React.FC = () => (
  <StyledContentLoader uniqueKey="LessonListFilterSkeleton">
    <rect
      x="0"
      y="0"
      rx={FILTER_HEIGHT / 2}
      ry={FILTER_HEIGHT / 2}
      width={FILTER_WIDTH}
      height={FILTER_HEIGHT}
    />
  </StyledContentLoader>
)

const StyledContentLoader = styled(ContentLoader)`
  height: ${FILTER_HEIGHT}px;
  width: ${FILTER_WIDTH}px;
`
