import { format } from 'date-fns'
import { NextRouter, useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { useEffect, useRef } from 'react'

import { ILessonFiltersState } from '@/reducers/lessonFilters'
import { isSame } from '@/utils/misc'

const NUMERIC_FILTERS = [
  'pageIndex',
  'postalCodes',
  'placeIds',
  'teacherIds',
  'teachedByIds',
  'timeslotIds',
  'durationIds',
  'levelIds',
  'disciplineIds',
  'objectiveIds',
]
const URL_PARAMS_MAPPING: [string, string][] = [] // filtersState, urlParam

export interface IArgs<T> {
  initialFiltersState: T
  filtersState: T
  filtersDispatch: (action: any) => void
  scope: 'lesson' | 'teacher' | 'video'
  hiddenFilterKeys?: string[]
}

export const useSyncUrlFilters = <T extends object>({
  initialFiltersState,
  filtersState,
  filtersDispatch,
  scope,
  hiddenFilterKeys = [],
}: IArgs<T>): void => {
  const router = useRouter()
  const prevFiltersState = useRef<T>()
  const mergeStateOnce = useRef<boolean>(false)

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    const mergedFiltersState = {
      ...initialFiltersState,
      ...parseUrlForFilters<T>(initialFiltersState, router.query, scope),
    }

    if (isSame(mergedFiltersState, prevFiltersState.current)) {
      return
    }

    filtersDispatch({
      type: 'overwriteSome',
      overwrittenFilters: mergedFiltersState,
    })

    prevFiltersState.current = mergedFiltersState
  }, [router.isReady, router.query, initialFiltersState])

  useEffect(() => {
    // Priorize parseUrl
    if (!prevFiltersState.current) {
      return
    }

    const mergedFiltersState = {
      ...filtersState,
      ...(!mergeStateOnce.current && prevFiltersState.current),
    }

    if (!mergeStateOnce.current) {
      mergeStateOnce.current = true
      return
    }

    if (isSame(mergedFiltersState, prevFiltersState.current)) {
      return
    }

    router.push(
      buildUrlFromFiltersState<T>(
        mergedFiltersState,
        initialFiltersState,
        router,
        hiddenFilterKeys,
        scope
      ),
      undefined,
      {
        shallow: true,
      }
    )

    prevFiltersState.current = mergedFiltersState
  }, [filtersState])
}

const parseUrlForFilters = <T,>(
  initialFiltersState: T,
  urlQuery: ParsedUrlQuery,
  scope: IArgs<T>['scope']
): Partial<T> => {
  const customUrlParamsMapping = new Map(URL_PARAMS_MAPPING)

  return Object.entries(initialFiltersState).reduce((acc, [filterKey, filterValues]) => {
    const paramValue = urlQuery[customUrlParamsMapping.get(filterKey) ?? filterKey]

    // Special case area
    if (scope === 'lesson' && ['areaKey', 'postalCodes'].includes(filterKey)) {
      return processUrlArea(acc, filterKey, filterValues, paramValue)
    }

    if (!paramValue) {
      return acc
    }

    // Special case calendar
    if (scope === 'lesson' && ['endAt', 'startAt'].includes(filterKey)) {
      return processUrlCalendar(acc, filterKey, filterValues, paramValue as string)
    }

    return Array.isArray(filterValues)
      ? processUrlValues(acc, filterKey, paramValue)
      : processUrlValue(acc, filterKey, paramValue)
  }, {}) as Partial<T>
}

const processUrlArea = (
  acc: any,
  filterKey: string,
  filterValues: any,
  paramValue: string | string[]
) => {
  // areaKey? Keep current value
  if (filterKey === 'areaKey') {
    acc[filterKey] = filterValues
    return acc
  }

  // PostalCodes? Keep current value if defined (areaChild case)
  acc[filterKey] = filterValues
  // If additionnal postalCodes from url, filter them to remove current value
  if (paramValue) {
    const addsPostalCodes = !Array.isArray(paramValue)
      ? [paramValue]
      : (paramValue as string[])
    const addsPostalCodesFiltered = addsPostalCodes.reduce((accc, postalCode) => {
      if (postalCode === filterValues) {
        return accc
      }

      return [...accc, +postalCode]
    }, [])

    acc[filterKey] = [...acc[filterKey], ...addsPostalCodesFiltered]
  }

  return acc
}

const processUrlCalendar = (
  acc: any,
  filterKey: string,
  filterValues: any,
  paramValue: string
) => {
  // Invalid date? Reset it
  if (isNaN(Date.parse(paramValue))) {
    acc[filterKey] = null
    return acc
  }

  // endAt < startAt? Reset endAt
  if (
    filterKey === 'endAt' &&
    acc.startAt &&
    new Date(paramValue) < new Date(acc.startAt)
  ) {
    acc[filterKey] = null
    return acc
  }

  acc[filterKey] = paramValue
  return acc
}

const processUrlValues = (acc: any, filterKey: string, paramValue: string | string[]) => {
  acc[filterKey] = !Array.isArray(paramValue) ? [paramValue] : (paramValue as any[])

  // Special case for filters that expect numeric type
  if (NUMERIC_FILTERS.includes(filterKey)) {
    acc[filterKey] = acc[filterKey].map((s: string): number => +s)
    return acc
  }

  return acc
}

const processUrlValue = (acc: any, filterKey: string, paramValue: string | string[]) => {
  // Special case for filters that expect numeric type
  if (NUMERIC_FILTERS.includes(filterKey)) {
    acc[filterKey] = +paramValue
    return acc
  }

  acc[filterKey] = paramValue
  return acc
}

const buildUrlFromFiltersState = <T,>(
  filtersState: T,
  initialFiltersState: T,
  router: NextRouter,
  hiddenFilterKeys: string[],
  scope: IArgs<T>['scope']
) => {
  const filtersUrlParams = Object.entries(filtersState)
    .filter(([filterKey, filterValues]) => {
      // Ignore some filters with default values
      if (['pageIndex', 'sortKey', 'startAt'].includes(filterKey)) {
        const filterValue = NUMERIC_FILTERS.includes(filterKey)
          ? +filterValues
          : filterValues

        return filterValue !== initialFiltersState[filterKey]
      }

      if (hiddenFilterKeys.includes(filterKey)) {
        return false
      }

      return Array.isArray(filterValues) ? filterValues.length : filterValues
    })
    .reduce((acc, [filterKey, filterValues]) => {
      // Special case
      if (scope === 'lesson' && ['areaKey', 'postalCodes'].includes(filterKey)) {
        // areaKey? Ignore it, return
        if (filterKey === 'areaKey') {
          return acc
        }

        // Default postalCodes defined? (areaChild case),
        // filter it from other postalCodes and continue processing
        if (initialFiltersState['postalCodes'].length) {
          filterValues = (filterValues as number[]).filter(
            (postalCode) => !initialFiltersState['postalCodes'].includes(postalCode)
          )
        }
      }

      if (Array.isArray(filterValues)) {
        const arr = (filterValues as string[]).reduce(
          (accc, filterValue) => [...accc, [filterKey, filterValue]],
          []
        )
        return [...acc, ...arr.sort()]
      }

      return [...acc, [filterKey, filterValues]]
    }, [])

  const allUrlParams = [...filtersUrlParams, ...getOtherParams(router.query)]

  return `${router.asPath.split('?')[0]}${
    allUrlParams.length ? `?${new URLSearchParams(allUrlParams).toString()}` : ''
  }`
}

const getOtherParams = (urlQuery: ParsedUrlQuery) => {
  if (!urlQuery) {
    return
  }

  return Object.entries(urlQuery).filter(
    ([key]) => key.startsWith('utm_') || key === 'map'
  )
}
