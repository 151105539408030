import React, { Children } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { CustomButton } from '@/components/_custom/CustomButton'
import { RedoIcon } from '@/components/_svg/icons/RedoIcon'
import { ILessonFiltersDispatch } from '@/reducers/lessonFilters'
import { IVideoFiltersDispatch } from '@/reducers/videoFilters'
import { DEVICE_WIDTH } from '@/utils/constants'

interface IProps {
  dispatch: ILessonFiltersDispatch | IVideoFiltersDispatch
}

export const FiltersTags: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  dispatch,
}) => (
  <Wrapper $isVisible={!!Children.count(children)}>
    {!!Children.count(children) && (
      <ResetButton onClick={() => dispatch({ type: 'resetAll' })}>
        <StyledRedoIcon />
        <FormattedMessage
          defaultMessage="Tout réinitialiser"
          description="FiltersTag: clear all filters"
        />
      </ResetButton>
    )}
    {children}
  </Wrapper>
)

const Wrapper = styled.div<{ $isVisible: boolean }>`
  display: none;

  @media (min-width: ${DEVICE_WIDTH.TABLET}px) {
    align-items: center;
    display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing.xs};
    justify-content: flex-start;
    margin-top: ${({ theme }) => theme.spacing.s};
  }
`
const ResetButton = styled(CustomButton)`
  ${({ theme }) => theme.typo.tagFilters}

  align-items: center;
  align-self: flex-start;
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
  padding: ${({ theme }) => theme.spacing.xxs} ${({ theme }) => theme.spacing.xs};
`
const StyledRedoIcon = styled(RedoIcon)`
  fill: ${({ theme }) => theme.color.white};
  height: ${({ theme }) => theme.spacing.s};
  width: ${({ theme }) => theme.spacing.s};
`
