import React from 'react'
import styled from 'styled-components'

import { CustomButton } from '@/components/_custom/CustomButton'
import { CloseIcon } from '@/components/_svg/icons/CloseIcon'

interface IRemovableSelectionProps {
  options: { label: string; value: string | number }[]
  filterValues: (string | number)[]
  onRemoveFn: (value: string | number) => void
}

export const RemovableSelectionList: React.FC<IRemovableSelectionProps> = ({
  options,
  filterValues,
  onRemoveFn,
}) => {
  const selectedFilters = options.filter((filter) => filterValues.includes(filter.value))

  return (
    <Wrapper>
      {selectedFilters.map((selectedFilter) => (
        <RemovableFilterItem
          onClick={() => onRemoveFn(selectedFilter.value)}
          key={`rmSel-${selectedFilter.value}`}
          outlined
        >
          {selectedFilter.label}
          <StyledCloseIcon />
        </RemovableFilterItem>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
`
const RemovableFilterItem = styled(CustomButton)`
  ${({ theme }) => theme.typo.tagFilters}

  align-items: center;
  align-self: stretch;
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
  padding: ${({ theme }) => theme.spacing.xxs} ${({ theme }) => theme.spacing.xs};

  &:hover > svg {
    fill: ${({ theme }) => theme.color.white};
  }
`
const StyledCloseIcon = styled(CloseIcon)`
  fill: ${({ theme }) => theme.color.terracota};
  height: ${({ theme }) => theme.spacing.xs};
  width: ${({ theme }) => theme.spacing.xs};
`
