import React from 'react'
import { Option as IMultiSelectOption } from 'react-multi-select-component'
import styled, { css } from 'styled-components'

import { SelectInput } from '@/components/_shared/form/SelectInput'
import { CheckedIcon } from '@/components/_svg/icons/CheckedIcon'

interface ISelectorProps {
  options: IMultiSelectOption[]
  selectedValues: (string | number)[]
  onSelectFn: (selectedValues: (string | number)[]) => void
  placeholder: string
}

export const Selector: React.FC<ISelectorProps> = ({
  options,
  selectedValues,
  onSelectFn,
  placeholder,
}) => (
  <SelectInput
    selectedValues={selectedValues}
    labelledBy={placeholder}
    valueRenderer={() => placeholder}
    options={options}
    onChange={(opts: IMultiSelectOption[]) => {
      const selectedValues = opts.map((option) => option.value)
      onSelectFn(selectedValues)
    }}
    ItemRenderer={ItemRenderer}
  />
)

export interface IItemRendererProps<T = IMultiSelectOption> {
  checked: boolean
  option: T
  onClick: () => void
  disabled?: boolean
}

export const ItemRenderer: React.FC<IItemRendererProps> = ({
  checked,
  option,
  disabled,
  onClick,
}) => (
  <SelectorItemWrapper
    key={option.value}
    className={`item-renderer ${disabled && 'disabled'}`}
    $disabled={disabled}
  >
    <SelectorCheckBoxInput
      checked={checked}
      onClick={onClick}
      disabled={disabled}
      readOnly
    />
    {checked && <SelectorCheckedIcon />}
    <SelectorLabel $checked={checked}>{option.label}</SelectorLabel>
  </SelectorItemWrapper>
)

export const SelectorItemWrapper = styled.label<{ $disabled: boolean }>`
  align-items: center !important;
  display: flex;
  position: relative;
  ${({ $disabled = false }) =>
    !$disabled &&
    css`
      cursor: pointer;
    `}
`
export const SelectorCheckBoxInput = styled.input.attrs(() => ({
  type: 'checkbox',
  tabIndex: -1,
}))`
  appearance: none;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.terracota};
  border-radius: ${({ theme }) => theme.spacing.xxs};
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;

  &:checked {
    background-color: ${({ theme }) => theme.color.terracota};
  }
`
export const SelectorLabel = styled.span<{ $checked: boolean }>`
  color: ${({ theme, $checked }) => ($checked ? theme.color.black : theme.color.grey)};
`
export const SelectorCheckedIcon = styled(CheckedIcon)`
  fill: ${({ theme }) => theme.color.white};
  height: 12px;
  margin-left: ${({ theme }) => theme.spacing.xxs};
  position: absolute;
  width: 12px;
`
