import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { CloseIcon } from '@/components/_svg/icons/CloseIcon'
import { DEVICE_WIDTH } from '@/utils/constants'

interface IProps {
  showFiltersSelector: boolean
  toggleFiltersSelector: () => void
}

export const FiltersSelectors: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  showFiltersSelector,
  toggleFiltersSelector,
}) => (
  <Wrapper $isVisible={showFiltersSelector}>
    <Header>
      <CloseButton onClick={toggleFiltersSelector}>
        <StyledCloseIcon />
      </CloseButton>

      <FormattedMessage
        tagName={HeaderTitle}
        defaultMessage="Filtrer"
        description="FiltersSelectors : title"
      />
    </Header>
    <Content>{children}</Content>
  </Wrapper>
)

const Wrapper = styled.div<{ $isVisible: boolean }>`
  background-color: ${({ theme }) => theme.color.white};
  display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  flex: 1;
  inset: 0;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.filters};

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    display: block;
    height: ${({ theme }) => theme.spacing.l};
    margin-bottom: 2px;
    position: relative;
    z-index: 2;
    flex-basis: 930px;
  }
`
const Header = styled.div`
  align-items: center;
  display: flex;
  padding: ${({ theme }) => theme.spacing.ms};
  width: 100%;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    display: none;
  }
`
const Content = styled.div`
  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    align-items: start;
    display: flex;
    justify-content: flex-start;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 0;

    & > *:not(:nth-last-child(-n + 2)) {
      margin-right: 12px;
    }
  }
`
const HeaderTitle = styled.p`
  ${({ theme }) => theme.typo.h5}
  ${({ theme }) => theme.typo.bold}

  flex: 1;
  margin-right: ${({ theme }) => theme.spacing.ms};
  text-align: center;
`
const CloseButton = styled.button`
  line-height: 0;
`
const StyledCloseIcon = styled(CloseIcon)`
  fill: ${({ theme }) => theme.color.terracota};
  height: ${({ theme }) => theme.spacing.s};
  width: ${({ theme }) => theme.spacing.s};
`
