import React from 'react'
import { useIntl } from 'react-intl'
import {
  MultiSelect,
  Option as IMultiSelectOption,
  SelectProps as IMultiSelectProps,
} from 'react-multi-select-component'
import styled from 'styled-components'

import { DEVICE_WIDTH } from '@/utils/constants'
import { showNbItems } from '@/utils/style'

interface IProps extends Omit<IMultiSelectProps, 'value'> {
  selectedValues: unknown[]
  showFiltersNumber?: boolean
  showClearButton?: boolean
}

export const SelectInput: React.FC<IProps> = ({
  selectedValues = [],
  showFiltersNumber = true,
  showClearButton = false,
  ...multiSelectProps
}) => {
  const intl = useIntl()

  const values = multiSelectProps.options.filter((option: IMultiSelectOption) =>
    selectedValues.includes(option.value)
  )

  return (
    <StyledMultiSelect
      value={values}
      overrideStrings={{
        search: intl.formatMessage({
          defaultMessage: 'Rechercher...',
          description: 'SelectInput: search placeholder',
        }),
        selectSomeItems: intl.formatMessage({
          defaultMessage: 'Sélectionner...',
          description: 'SelectInput: selectSomeItems placeholder',
        }),
      }}
      hasSelectAll={false}
      $nbSelectedValues={showFiltersNumber && selectedValues.length}
      $showClearButton={showClearButton}
      {...multiSelectProps}
    />
  )
}

const StyledMultiSelect = styled(MultiSelect)<{
  $nbSelectedValues: number
  $showClearButton: boolean
}>`
  &.rmsc {
    outline: none;

    * {
      outline: none;
      transition: none;
    }

    .dropdown-container {
      border: 1px solid ${({ theme }) => theme.color.greyLight};
      border-left: none;
      border-radius: 0;
      border-right: none;
      margin-bottom: -1px;
      padding: 0;

      &:focus-within {
        border-color: ${({ theme }) => theme.color.greyLight};
        box-shadow: none;
      }

      @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
        border: 1px solid ${({ theme }) => theme.color.greyLight};
        border-radius: ${({ theme }) => theme.spacing.ms};
        overflow: hidden;
      }

      .dropdown-heading {
        cursor: pointer;
        height: fit-content;
        padding: 12px ${({ theme }) => theme.spacing.ms};

        @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
          padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.s};
        }

        .dropdown-heading-value {
          ${({ $nbSelectedValues }) => showNbItems($nbSelectedValues)};
          span,
          .gray {
            ${({ theme }) => theme.typo.bold}
            color: ${({ theme }) => theme.color.black};

            @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
              font-family: inherit;
            }
          }
        }

        .clear-selected-button {
          display: ${({ $showClearButton }) => ($showClearButton ? 'flex' : 'none')};
        }

        .dropdown-heading-dropdown-arrow.gray {
          color: ${({ theme }) => theme.color.terracota};
          margin-right: -${({ theme }) => theme.spacing.xxs};
        }
      }

      .dropdown-content {
        border-top: 1px solid ${({ theme }) => theme.color.greyLight};
        padding-top: 0;
        position: relative;
        z-index: inherit;

        .panel-content {
          background-color: ${({ theme }) => theme.color.white};
          border-radius: 0;
          box-shadow: inherit;

          @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
            border-bottom-left-radius: ${({ theme }) => theme.spacing.s};
            border-bottom-right-radius: ${({ theme }) => theme.spacing.s};
          }

          .select-panel {
            padding: 0 ${({ theme }) => theme.spacing.ms};

            @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
              padding: 0;
            }

            .search {
              border: none;
              border-radius: 0;
              margin-bottom: ${({ theme }) => theme.spacing.xs};

              input {
                ${({ theme }) => theme.typo.mention}
                border: 1px solid ${({ theme }) => theme.color.greyLight};
                border-radius: 48px;
                color: ${({ theme }) => theme.color.grey};
                height: fit-content;
                margin-top: ${({ theme }) => theme.spacing.s};
                padding: 12px;

                @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
                  border-left: none;
                  border-radius: 0;
                  border-right: none;
                  border-top: none;
                  margin-top: 0;
                }
              }
            }

            .options {
              margin: 12px 0;
              max-height: 210px;

              @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
                margin: 0;
              }

              &::-webkit-scrollbar {
                width: ${({ theme }) => theme.spacing.xs};
              }

              &::-webkit-scrollbar-thumb {
                background-color: ${({ theme }) => theme.color.greyLight};
                border: 1px solid ${({ theme }) => theme.color.white};
                border-radius: ${({ theme }) => theme.spacing.xxs};
              }

              li {
                padding: ${({ theme }) => `${theme.spacing.xxs} 0`};
                text-align: left;

                @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
                  padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
                }
              }

              .select-item {
                padding: 0;

                @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
                  padding: inherit;
                }

                &:hover,
                &:focus,
                &.selected {
                  background-color: ${({ theme }) => theme.color.white};
                }
              }
            }
          }
        }
      }
    }
  }
`
