import React from 'react'
import styled from 'styled-components'

import { CustomButton } from '@/components/_custom/CustomButton'
import { CloseIcon } from '@/components/_svg/icons/CloseIcon'
import { IPropertyKey, IPropertyValue } from '@/types/main'
import { flattenPropertyValues } from '@/utils/decorator'

interface IProps {
  propKey: IPropertyKey
  selectedValues: (string | number)[]
  onRemoveFn: (value: string | number) => void
}

export const PropertyRemovableSelectionList: React.FC<IProps> = ({
  propKey,
  selectedValues,
  onRemoveFn,
}) => {
  const selectedPropValueList = flattenPropertyValues(propKey.propValues).filter(
    (propValue) => selectedValues.includes(propValue._id)
  )

  return (
    <>
      {selectedPropValueList.map((propValue) => (
        <RemovableFilter
          key={`rmSel-${propValue._id}`}
          onClick={() => onClick(propValue, onRemoveFn)}
          outlined
        >
          {propValue.title}
          <StyledCloseIcon />
        </RemovableFilter>
      ))}
    </>
  )
}

const onClick = (
  propValue: IPropertyValue,
  onRemoveFn: (value: string | number) => void
) => {
  // if propValue has a parent, unselect parent when unselect propValue
  if (propValue.parent) {
    onRemoveFn(propValue.parent._id)
  }

  // if propValue has children, unselect all children when unselect propValue
  if (propValue.children.length > 0) {
    propValue.children.map((children) => onRemoveFn(children._id))
  }

  return onRemoveFn(propValue._id)
}

const RemovableFilter = styled(CustomButton)`
  ${({ theme }) => theme.typo.tagFilters}

  align-items: center;
  align-self: stretch;
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
  padding: ${({ theme }) => theme.spacing.xxs} ${({ theme }) => theme.spacing.xs};

  &:hover > svg {
    fill: ${({ theme }) => theme.color.white};
  }
`
const StyledCloseIcon = styled(CloseIcon)`
  fill: ${({ theme }) => theme.color.terracota};
  height: ${({ theme }) => theme.spacing.xs};
  width: ${({ theme }) => theme.spacing.xs};
`
